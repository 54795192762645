// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  var tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
  var popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  var popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
  // var toastElList = document.querySelectorAll('.toast')
  // var toastList = [...toastElList].map(toastEl => new bootstrap.Toast(toastEl, option))
})
// See: https://www.ducktypelabs.com/turbo-break-out-and-redirect/
Turbo.StreamActions.redirect = function () {
    Turbo.visit(this.target);
};

